import { LocationApi } from "@/api"
import Icon from "@/components/base/Icon"
import { Input, Button } from "@dilypse/mui/base";

import BaseSelect from "@/components/base/select"
import { useDebounce } from "@/hooks/useDebounce"
import useNotification from "@/hooks/useNotification"
import useSelectAccount from "@/hooks/useSelectAccount"
import GoogleStore from "@/store/google"
import { Paper, Stack } from "@mui/material"
import IconButton from "@mui/material/IconButton/IconButton"
import { useCallback, useEffect } from "react"
import { useTranslation } from "react-i18next"
import { formControlSx, updateButtonSx } from "../styles"

interface FilterFormProps {
    disabled: boolean
}

const FilterForm = (props: FilterFormProps) => {
    const { disabled = false } = props
    const { t } = useTranslation()
    const {
        companyId,
        gmb_access_id,
        multiple_accounts,
        accounts,
        account_id,
        gbp_accounts,
        callbackUrl,
        user_id,
        gbp_account,
        filter,
        is_fetching,
        missing_scope,
        missing_token,
        update,
    } = GoogleStore()
    const { openGoogleAccount } = useSelectAccount()
    const { notif } = useNotification()
    const searchValue = useDebounce(filter, 400)

    // Changement ana gbp-account

    const fetchLocations = useCallback(
        async (account_id: string) => {
            if (account_id && gbp_account && companyId) {
                update({
                    is_fetching: true,
                    missing_scope: false,
                })

                const resp = await LocationApi.fetchLocations({
                    companyId,
                    filter: searchValue,
                    gmb_access_id: gbp_account,
                    account_id,
                })
                if (!resp?.error) {
                    update({
                        selected_businesses: [],
                        locations: resp || [],
                        is_fetching: false,
                    })
                } else {
                    update({
                        selected_businesses: [],
                        locations: [],
                        is_fetching: false,
                    })

                    notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
                }
            }
        },
        [searchValue, gbp_account]
    )

    const handleGbpAccount = useCallback(
        async (data: any) => {
            if (data?.type === "button") {
                openGoogleAccount({
                    companyId,
                    user_id,
                    cb: callbackUrl,
                    access_id: "",
                    multiple_accounts,
                    add_account: true,
                })
            } else {
                update({
                    gbp_account: data?.value,
                })
            }
        },
        [update, callbackUrl, gbp_account, companyId, user_id]
    )

    const fetchAccounts = useCallback(async () => {
        if (companyId && gbp_account && !missing_scope) {
            update({
                is_fetching: true,
                selected_businesses: [],
                locations: null,
                missing_token: false,
            })
            const resp = await LocationApi.fetchAccounts({
                companyId,
                access_id: gbp_account,
            })
            if (resp?.error || resp?.baseName === null) {
                const revoked =
                    resp?.error?.error === "REFRESH_TOKEN_REVOKED" || resp?.error === "REFRESH_TOKEN_REVOKED"
                let gbp_name = null

                if (revoked) {
                    gbp_name = gbp_accounts?.find((obj: any) => obj?.access_id === gbp_account)?.email ?? ""
                }
                update({
                    accounts: [],
                    is_fetching: false,
                    missing_token: revoked,
                    ...(revoked ? { compte_name: gbp_name } : {}),
                })
            } else {
                const account = resp?.find((obj: any) =>
                    ["ORGANIZATION", "LOCATION_GROUP", "PERSONAL"].includes(obj?.type)
                )

                update({
                    accounts: resp,
                    account_id: account?.length > 0 ? account[0]?.name : account?.name,
                    is_fetching: false,
                })
                fetchLocations(account?.length > 0 ? account[0]?.name : account?.name)
            }
        }
    }, [companyId, gbp_account, missing_scope])

    // tsy manao fetch ra tsy efa misy access_id

    const fetchGBPAccount = useCallback(async () => {
        if (companyId && !missing_scope) {
            update({
                is_fetching: true,
                selected_businesses: [],
                missing_token: false,
            })
            const resp = await LocationApi.getGbpAccount(companyId)
            if (!resp?.error && resp?.length) {
                update({
                    gbp_account: gmb_access_id || resp?.[0]?.access_id,
                    gbp_accounts: resp,
                })
            } else {
                update({
                    missing_scope: false,
                    is_fetching: false,
                })

                notif({ message: t("SYSTEM_ERROR.INVALID_REQUEST"), type: "ERROR" })
            }
        }
    }, [companyId, gmb_access_id, missing_scope, update])

    const handleSelectAccount = useCallback(
        async (data: any) => {
            fetchLocations(data.value !== "not-grouped" ? data.value : "")

            update({
                account_id: data.value !== "not-grouped" ? data.value : "",
                selected_businesses: [],
            })
        },
        [update, fetchLocations]
    )

    const refreshData = useCallback(() => {
        fetchLocations(account_id)
    }, [account_id, update])

    useEffect(() => {
        fetchGBPAccount()
    }, [fetchGBPAccount])

    useEffect(() => {
        fetchAccounts()
    }, [fetchAccounts])

    useEffect(() => {
        fetchLocations(account_id)
    }, [searchValue])

    return (
        <Paper elevation={0} sx={{ p: 1 }}>
            <Stack flexDirection={"row"}>
                <form
                    className="filter-inputs"
                    data-e2e="google-filter"
                    data-testid="google-filter"
                    onSubmit={(e) => {
                        e.preventDefault()
                        refreshData()
                    }}
                >
                    <Input
                        label={t("BUSINESSES.OAUTH.SEARCH")}
                        data-testid="search"
                        disabled={is_fetching ?? disabled}
                        onInput={(event) => update({ filter: (event.target as HTMLInputElement).value })}
                        sx={{
                            maxWidth: "240px",
                        }}
                        endAdornment={
                            <IconButton type="submit" onClick={refreshData}>
                                <Icon name={"search"} />
                            </IconButton>
                        }
                    />

                    <BaseSelect
                        value={gbp_account}
                        disabled={is_fetching ?? disabled}
                        options={
                            gbp_accounts?.length
                                ? [
                                    ...gbp_accounts.map((account) => ({
                                        label: account?.email || "",
                                        value: account?.access_id || "",
                                    })),
                                    {
                                        value: "none",
                                        label: t("BUSINESSES.OAUTH.CONNECT"),
                                        type: "button",
                                        icon: "unicolorGoogle",
                                    },
                                ]
                                : [
                                    {
                                        value: "none",
                                        label: t("BUSINESSES.OAUTH.CONNECT"),
                                        type: "button",
                                        icon: "unicolorGoogle",
                                    },
                                ]
                        }
                        label={t("BUSINESSES.OAUTH.ACCOUNT")}
                        formControlSx={formControlSx}
                        onItemClicked={(e) => handleGbpAccount(e)}
                    />

                    <BaseSelect
                        value={account_id}
                        dataTestId="accounts"
                        disabled={missing_token || is_fetching || disabled}
                        options={
                            accounts?.length
                                ? [
                                    ...accounts.map((account) => ({
                                        label: account.accountName || "",
                                        value: account.name || "",
                                    })),
                                ]
                                : [{ value: "", label: t("BUSINESSES.OAUTH.NOT_GROUPED") }]
                        }
                        label={t("BUSINESSES.OAUTH.SELECT_GROUP")}
                        formControlSx={formControlSx}
                        onItemClicked={(e) => handleSelectAccount(e)}
                    />
                </form>
                <Button variant="text" sx={updateButtonSx} onClick={refreshData}>
                    <Icon name="refresh" />
                    {t("UPDATE")}
                </Button>
            </Stack>
        </Paper>
    )
}

export default FilterForm
